import React from 'react'
import { graphql } from 'gatsby'
import BackgroundImg from 'gatsby-background-image'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { convertToBgImage } from 'gbimage-bridge'
import { useIntl } from 'gatsby-plugin-react-intl'
import parse from 'html-react-parser'

// Components
import Pagina from '../components/Pagina'
import FloatingItem from '../components/FloatingItem/floating-icon'

const Paralax = ({ paralax, paralaxMobile }) => {
  const intl = useIntl()

  const image = getImage(paralax)
  const bgImage = convertToBgImage(image)
  const imageMobile = getImage(paralaxMobile)
  const bgImageMobile = convertToBgImage(imageMobile)
  return <>
    <div>
      <BackgroundImg
        {...bgImage}
        Tag="div"
        className="paralaxImage d-lg-block d-none"
      >
        <div className="container h-100 d-flex justify-content-start ">
          <div className=" h-100 d-flex align-items-center">
            <div className="w-100">
              <h1 className="pb-4 text-white">{parse(intl.formatMessage({ id: 'paginas.scrap_produtivo_e_descaracterizado.paralax.title' }))}</h1>
            </div>
          </div>
        </div>
      </BackgroundImg>
      <BackgroundImg
        {...bgImageMobile}
        Tag="div"
        className="paralaxImage d-lg-none d-block"
      >
        <div className="container h-100 pt-5">
          <div className=" h-100 d-flex pl-5 pt-5 align-items-center">
            <div className="w-100">
              <h1 className="text-white">{parse(intl.formatMessage({ id: 'paginas.scrap_produtivo_e_descaracterizado.paralax.title' }))}</h1>
            </div>
          </div>
        </div>
      </BackgroundImg>
      <div className="gradient-blue-to-green"></div>
    </div>
  </>
}

const Scrap = ({ data }) => {
  const intl = useIntl()

  return <>
    <Pagina pagina={data.pagina} />
    <FloatingItem link='https://api.whatsapp.com/send?phone=5508007010088&text=Olá, quero solicitar uma visita de um consultor.'/>
    <section className="full m-0 p-0">
      <Paralax paralax={data.paralax.childImageSharp.gatsbyImageData} paralaxMobile={data.paralaxMobile.childImageSharp.gatsbyImageData} />
      <div className="container d-none d-lg-block">
        <div className="row">
          <div className='col-6 py-5'>
            <h5 className='font-size-12 text-lwart-lightblue2 line-height-normal letter-space-0'>{parse(intl.formatMessage({ id: 'paginas.scrap_produtivo_e_descaracterizado.scrap.title1' }))}</h5>
            <h5 className='font-size-12 py-3 letter-space-0'>{parse(intl.formatMessage({ id: 'paginas.scrap_produtivo_e_descaracterizado.scrap.title2' }))}
            </h5>
            <a href='https://api.whatsapp.com/send?phone=5508007010088' target="_blank" rel="noopener noreferrer" className='font-Spinnaker font-size-12'><u>{parse(intl.formatMessage({ id: 'paginas.scrap_produtivo_e_descaracterizado.scrap.link' }))}</u></a>
          </div>
          <div className='col-6 p-5'>
            <GatsbyImage image={data.scrap.childImageSharp.gatsbyImageData} alt='' />
          </div>
        </div>
      </div>
      <div className="container d-block d-lg-none">
        <div className='py-5'>
          <h5 className='font-size-1125 text-lwart-lightblue2 line-height-normal letter-space-0'>{parse(intl.formatMessage({ id: 'paginas.scrap_produtivo_e_descaracterizado.scrap.title1' }))}</h5>
          <h5 className='font-size-1125 py-3 letter-space-0'>{parse(intl.formatMessage({ id: 'paginas.scrap_produtivo_e_descaracterizado.scrap.title2' }))}
          </h5>
          <a href='https://api.whatsapp.com/send?phone=5508007010088' target="_blank" rel="noopener noreferrer" className='font-Spinnaker font-size-1125'><u>{parse(intl.formatMessage({ id: 'paginas.scrap_produtivo_e_descaracterizado.scrap.link' }))}</u></a>
        </div>
        <div className='pb-5'>
          <GatsbyImage image={data.scrap.childImageSharp.gatsbyImageData} alt='' />
        </div>
      </div>
    </section>
    <div className="gradient-blue-to-green"></div>
  </>
}

export default Scrap

export const query = graphql`{
  pagina: contentfulPagina(path: {eq: "/scrap-produtivo-e-descaracterizacao/"}) {
    path
    metaTitle
    metaDescription
    pageTitle
    tags
    ogImage {
      localFile {
        publicURL
      }
    }
  }
  paralax: file(relativePath: {eq: "scrap-produtivo-e-descaracterizacao/banner.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  paralaxMobile: file(relativePath: {eq: "scrap-produtivo-e-descaracterizacao/scrap-produtivo.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  scrap: file(relativePath: {eq: "scrap-produtivo-e-descaracterizacao/scrap.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
}
`
